<template>
    <!--    todo 验证功能待做 验证不能为空等-->
    <div class="SupplierCreate" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card>
            <el-page-header @back="goBack" />
        </el-card>
        <el-card shadow="never" style="margin-top: 8px; text-align: left">
            <el-button type="primary" @click="handleSave" size="small">保存</el-button>
            <el-button type="primary" @click="handleAdd" size="small">添加</el-button>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px" align="left">
            <el-form :rules="rules" :model="form" ref="createForm">
                <el-table
                    id="printMe"
                    border
                    stripe
                    style="width: 100%"
                    :data="form.tableData"
                    size="mini"
                    :highlight-current-row="true"
                    max-height="500"
                >
                    <el-table-column label="序号" type="index" width="80" align="center" />
                    <el-table-column label="名称" width="160">
                        <template slot-scope="scope">
                            <el-form-item :prop="'tableData.' + scope.$index + '.names'" :rules="rules.names">
                                <el-input v-model.trim="scope.row.names" size="mini" />
                            </el-form-item>
                        </template>
                    </el-table-column>
                    <el-table-column label="联系人" width="160">
                        <template slot-scope="scope" props="linkMans">
                            <el-form-item :prop="'tableData.' + scope.$index + '.linkMans'" :rules="rules.linkMans">
                                <el-input v-model.trim="scope.row.linkMans" size="mini" />
                            </el-form-item>
                        </template>
                    </el-table-column>
                    <el-table-column label="手机号码" width="160">
                        <template slot-scope="scope">
                            <el-form-item :prop="'tableData.' + scope.$index + '.mobiles'" :rules="rules.mobiles">
                                <el-input v-model.trim="scope.row.mobiles" size="mini" />
                            </el-form-item>
                        </template>
                    </el-table-column>
                    <el-table-column label="地址" width="160">
                        <template slot-scope="scope">
                            <el-form-item :prop="'tableData.' + scope.$index + '.addresses'" :rules="rules.addresses">
                                <el-input v-model.trim="scope.row.addresses" size="mini" />
                            </el-form-item>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" width="180">
                        <template slot-scope="scope">
                            <el-button size="mini" type="danger" @click="handleDelete(scope.$index)">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </el-form>
        </el-card>
    </div>
</template>

<script>
import UrlUtils from 'js/UrlUtils';
import { mobileValidateRule } from 'js/validate/ValidateCommonRule';

export default {
    name: 'SupplierCreate',
    data() {
        return {
            newBar: '',
            form: {
                tableData: [],
            },
            url: {
                save: '/goods/supplier/createExtend',
            },
            loading: false,
            rules: {
                mobiles: mobileValidateRule(),
                names: [{ required: true, message: '请填写供应商名称', trigger: 'blur' }],
                linkMans: [{ required: true, message: '请填写联系人', trigger: 'blur' }],
                addresses: [{ required: true, message: '请填写地址', trigger: 'blur' }],
            },
        };
    },

    methods: {
        handleSave() {
            const _this = this;
            if (!this.form.tableData.length) {
                return false;
            }
            this.$refs.createForm.validate(async (valid) => {
                if (!valid) {
                    return false;
                }
                const _params = {
                    names: this.form.tableData.map((r) => r.names),
                    linkMans: this.form.tableData.map((r) => r.linkMans),
                    mobiles: this.form.tableData.map((r) => r.mobiles),
                    addresses: this.form.tableData.map((r) => r.addresses),
                };
                const set = Array.from(new Set(_params.names));
                if (set.length != _params.names.length) {
                    this.$message.error('名称不能重复');
                    return;
                }
                UrlUtils.PostRemote(_this, this.url.save, _params);
            });
        },
        handleAdd() {
            this.form.tableData = this.form.tableData.concat({
                linkMans: '',
                names: '',
                mobiles: '',
                addresses: '',
            });
        },
        handleDelete(index) {
            const _a = this.form.tableData;
            _a.splice(index, 1);
            this.form.tableData = _a;
        },
    },
};
</script>

<style scoped>
.BatchCreate .el-form-item {
    margin-bottom: 0;
}

.BatchCreate .el-table th.gutter {
    display: table-cell;
}
</style>
